import * as Stats from 'services/calculations/stats';

class EquivalenceSurvivalSampleSizeCalculator {

  constructor (calculatorInputs){
    this.equivalenceLimit = parseFloat(calculatorInputs.equivalenceLimit);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.power = parseFloat(calculatorInputs.power);
    this.hazardRatio = parseFloat(calculatorInputs.hazardRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'equivalenceLimit',
      'hazardRatio'
    ];
    return meaningfulInputs;
  }

  calculate() {
    let events = 3;
    let solutionReached = false;
    let answer = null;
    while(!solutionReached) {
      events += 1;
      let tau1 = (Math.sqrt(events) * Math.abs(Math.log(this.hazardRatio) - Math.log(this.equivalenceLimit)) /  Math.sqrt(2));
      let tau2 = (Math.sqrt(events) * Math.abs(Math.log(this.hazardRatio) + Math.log(this.equivalenceLimit)) /  Math.sqrt(2));

      let t1 = Stats.uGaussianInverse(1 - this.significanceLevel);
      let prob1 = Stats.uGaussian(tau1-t1);
      let prob2 = Stats.uGaussian(tau2-t1);
      answer=prob2+prob1-1;
      let d2 = this.power - answer;


      if (d2 <= 0) {
        solutionReached = true;
      }

    }
    return({events: events})
  }
}

export default EquivalenceSurvivalSampleSizeCalculator;
