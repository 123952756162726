import React from "react";
import { Field } from "redux-form";
import SelectField from "components/forms/SelectField";

const SignificanceInput = () => (
  <Field
    label="Significance"
    name="significance"
    component={SelectField}
    options={[[1, 'One-sided'], [2, 'Two-sided']]}
  />
);

export default SignificanceInput;
