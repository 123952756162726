const validNumber = function(number, options = {}) {
  const min = options.min;
  const max = options.max;

  if(!number || isNaN(Number(number)))
    return false;

  if(min && number < min)
    return false;

  if(max && number > max)
    return false;

  return true;
}
export default validNumber;
