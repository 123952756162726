import React from 'react';
import './fields.css';

const NumberField = function(props) {
  const { meta: { touched, error } } = props;
  const inputClass = `form-control ${touched && error ? 'is-invalid' : ''}`;
  const inputId = props.input.name;

  return(
    <div className='form-group'>
      <label htmlFor={inputId}>{props.label}</label>
      <input
        id={inputId}
        type="number"
        step="any"
        className={inputClass}
        {...props.input}
        noValidate
      />
      <div className="text-help text-danger">
        {touched ? error : ''}
      </div>
    </div>
  )
}

export default NumberField;
