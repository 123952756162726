import * as Stats from './stats';

class CustomT {

  constructor (x, nu, mu) {
    this.x = x;
    this.nu = nu;
    this.mu = mu;
  }

  cdfNoncentralT() {
    if(this.mu === 0) {
      return Stats.studentT(this.x, this.nu);
    } else if(this.x >= 0) {
      return this._fWithNuAndMuForX(this.nu, this.mu, this.x)
    } else {
      return 1 - this._fWithNuAndMuForX(this.nu, -this.mu, -this.x)
    }

  }

  _fWithNuAndMuForX(nu, mu, x) {

    let sum = 0;
    const y = (x ** 2) / ((x ** 2) + nu)


    for (let i = 0; i < 10; i++) {
      sum += (this._pForMu(i,mu) * (Stats.betaInc(i + 0.5, nu / 2, y))) + (this._qForMu(i, mu) * Stats.betaInc(i + 1, nu / 2, y));
    }
    return Stats.uGaussian(-1 * mu) + (0.5 * sum)
  }

  _pForMu(i, mu) {

    return (1/this._factorial(i)) * (Math.exp(-1 * ((mu ** 2) / 2)) ) * (((mu ** 2) / 2) ** i);
  }

  _qForMu(i, mu) {
    return (mu / (Math.sqrt(2) * Stats.gamma(i + 1.5))) * ((Math.exp(-1 * ((mu ** 2) / 2)))) * ((mu ** 2) / 2) ** i
  }

  _factorial(value) {
    let result = 1;
    for (let i = 2; i <= Math.floor(value); i++) {
      result = result * i ;
    }
    return result;
  }

}

export default CustomT
