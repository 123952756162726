import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";

const NonInferiorityLimitInput = () => (
  <Field
    label="Non Inferiority Limit"
    name="nonInferiorityLimit"
    component={NumberField}
  />
);

export default NonInferiorityLimitInput;
