import * as Stats from 'services/calculations/stats';

class NonInferiorityBinarySampleSizeCalculator {

  constructor (calculatorInputs) {
    this.power =  parseFloat(calculatorInputs.power);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.nonInferiorityLimit =  parseFloat(calculatorInputs.nonInferiorityLimit);
    this.responseA =  parseFloat(calculatorInputs.responseA);
    this.responseB =  parseFloat(calculatorInputs.responseB);
    this.design = calculatorInputs.design;
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'nonInferiorityLimit',
      'responseA',
      'responseB'
    ];
    return meaningfulInputs;
  }


  calculate() {
		let sampleSize = (((this.responseA * (1 - this.responseA)) + (this.responseB * (1 - this.responseB))) * Math.pow(Stats.uGaussianInverse(this.power) + Stats.uGaussianInverse(1 - this.significanceLevel), 2)) / Math.pow(Math.abs((this.responseA - this.responseB) - this.nonInferiorityLimit), 2);

    if (this._isCrossover()) {
      let nDisc = sampleSize *((this.responseA * (1 - this.responseB)) + (this.responseB * (1 - this.responseA)));
      return { totalSampleSize: Math.ceil(sampleSize), discordantSampleSize: Math.ceil(nDisc) }
    } else if (this._isParallel()) {
      return { sampleSizeGroup1: Math.ceil(sampleSize), sampleSizeGroup2: Math.ceil(sampleSize) };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default NonInferiorityBinarySampleSizeCalculator
