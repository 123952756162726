import * as Stats from 'services/calculations/stats';

class EquivalenceBinarySampleSizeCalculator {

  constructor (calculatorInputs) {
    this.power =  parseFloat(calculatorInputs.power);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.equivalenceLimit =  parseFloat(calculatorInputs.equivalenceLimit);
    this.responseA =  parseFloat(calculatorInputs.responseA);
    this.responseB =  parseFloat(calculatorInputs.responseB);
    this.design = calculatorInputs.design;
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'equivalenceLimit',
      'responseA',
      'responseB'
    ];
    return meaningfulInputs;
  }


  calculate() {
    let sampleSize = 1;
    let solutionReached = false;
    if (this.responseA === this.responseB) {
      const pMean = (this.responseA + this.responseB) / 2;
      let betaError = 1 - this.power;

      sampleSize=(( 2 * Math.pow((Stats.uGaussianInverse(1 - betaError / 2) + Stats.uGaussianInverse(1 - this.significanceLevel)), 2) * pMean * (1-pMean)) / Math.pow(this.equivalenceLimit, 2));



    } else {
      while(!solutionReached) {
        let currentPower = null;
        sampleSize += 1;

        const upperLimit = Math.sqrt((sampleSize * Math.pow(((this.responseA - this.responseB) - this.equivalenceLimit), 2)) / (this.responseA * (1 - this.responseA) + this.responseB * (1 - this.responseB)));
        const lowerLimit = Math.sqrt((sampleSize * Math.pow(((this.responseA - this.responseB) + this.equivalenceLimit), 2)) / (this.responseA * (1 - this.responseA) + this.responseB * (1 - this.responseB)));

        currentPower = Stats.uGaussian(upperLimit - Stats.uGaussianInverse(1 - this.significanceLevel)) + Stats.uGaussian(lowerLimit - Stats.uGaussianInverse(1 - this.significanceLevel)) - 1;

        if (currentPower >= this.power) {
          solutionReached = true;
        }

      }
    }

   if (this._isCrossover()) {
      let nDisc =  sampleSize * (this.responseA * (1 - this.responseB) + this.responseB * (1 - this.responseA));
      return { totalSampleSize: Math.ceil(sampleSize), discordantSampleSize: Math.ceil(nDisc) }
    } else if (this._isParallel()) {
      return { sampleSizeGroup1: Math.ceil(sampleSize), sampleSizeGroup2: Math.ceil(sampleSize) };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default EquivalenceBinarySampleSizeCalculator
