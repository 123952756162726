import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';
class EquivalenceSurvivalPoCalculator {

  constructor (calculatorInputs){
    this.equivalenceLimit = parseFloat(calculatorInputs.equivalenceLimit);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.sampleSize = parseFloat(calculatorInputs.sampleSize);
    this.hazardRatio = parseFloat(calculatorInputs.hazardRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'equivalenceLimit',
      'hazardRatio'
    ];
    return meaningfulInputs;
  }

  calculate() {
    let tau1 = (Math.sqrt(this.sampleSize) * Math.abs(Math.log(this.hazardRatio) - Math.log(this.equivalenceLimit)) /  Math.sqrt(2));
    let tau2 = (Math.sqrt(this.sampleSize) * Math.abs(Math.log(this.hazardRatio) + Math.log(this.equivalenceLimit)) /  Math.sqrt(2));

    let t1 = Stats.uGaussianInverse(1 - this.significanceLevel);
    let prob1 = Stats.uGaussian(tau1-t1);
    let prob2 = Stats.uGaussian(tau2-t1);
    let power =prob2+prob1-1;
    return({ power: _.round(power, 2) })
  }
}

export default EquivalenceSurvivalPoCalculator;
