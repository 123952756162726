import * as Stats from 'services/calculations/stats';

class PrecisionNormalSampleSizeCalculator {

  constructor (calculatorInputs) {
    this.precision =  parseFloat(calculatorInputs.precision);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.significance =  parseFloat(calculatorInputs.significance);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'precision',
      'significanceLevel',
      'meanDifference',
      'precisionLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {
    let sampleSize = 2;
    let solutionReached = false;
    let currentSampleSize = null;
    while(!solutionReached) {

      sampleSize += 1;
      if(this._isCrossover()) {
        currentSampleSize = 2 * (Stats.studentTInverse(1 - (this.significanceLevel / this.significance), sampleSize - 2) ** 2) * (this.populationStandardDeviation ** 2) / (this.precision ** 2);
      } else if(this._isParallel()) {
        currentSampleSize = (this.allocationRatio + 1) * (Stats.studentTInverse(1 - (this.significanceLevel / this.significance), sampleSize * (this.allocationRatio + 1) - 2) ** 2) * (this.populationStandardDeviation ** 2) / (this.allocationRatio * (this.precision ** 2));
      } else {
        throw new Error(`Unknown design: ${this.design}`);
      }

      if (sampleSize > currentSampleSize) {
        solutionReached = true;
      }

    }

    if(this._isCrossover()) {
      return { sampleSize: sampleSize };
    }

    if(this._isParallel()) {
      const sampleSizeGroup2 = Math.ceil(sampleSize * this.allocationRatio);
      const totalSampleSize  = sampleSizeGroup2 + sampleSize;
      return { sampleSizeGroup1: sampleSize, sampleSizeGroup2: sampleSizeGroup2 };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default PrecisionNormalSampleSizeCalculator
