import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";
import _ from 'lodash/math';

class SuperiorityNormalPowerCalculator {

  constructor (calculatorInputs) {
    this.design = calculatorInputs.design;
    this.sampleSize = parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel)
    this.significance = calculatorInputs.significance;
    this.meanDifference = parseFloat(calculatorInputs.meanDifference);
    this.populationStandardDeviation = parseFloat(calculatorInputs.populationStandardDeviation);
    this.allocationRatio = parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'significance',
      'meanDifference',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }

  calculate() {

    let t = null;
    let df = null;
    let mu = null;

    if(this._isCrossover()) {
      t  = Stats.studentTInverse(1 - this.significanceLevel/this.significance, this.sampleSize - 2);
      df = this.sampleSize - 2;
      mu = Math.sqrt(this.sampleSize * (this.meanDifference ** 2) / (2 * (this.populationStandardDeviation ** 2)));
    } else if(this._isParallel()) {
      t  = Stats.studentTInverse(1 - this.significanceLevel/this.significance, this.sampleSize * (this.allocationRatio + 1) - 2);
      df = this.sampleSize * (this.allocationRatio + 1) - 2;
      mu = Math.sqrt((this.allocationRatio * this.sampleSize * (this.meanDifference ** 2)) / ((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
    } else {
      throw new Error(`Unknown design: ${this.design}`);
    }

    const customTDistribution = new CustomT(t, df, mu);
    const power = 1 - customTDistribution.cdfNoncentralT();
    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }

  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }
}

export default SuperiorityNormalPowerCalculator;
