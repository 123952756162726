import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';

class NonInferiorityBinaryPowerCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.nonInferiorityLimit =  parseFloat(calculatorInputs.nonInferiorityLimit);
    this.responseA =  parseFloat(calculatorInputs.responseA);
    this.responseB =  parseFloat(calculatorInputs.responseB);
    this.design = calculatorInputs.design;
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'nonInferiorityLimit',
      'responseA',
      'responseB'
    ];
    return meaningfulInputs;
  }


  calculate() {
    const power = Stats.uGaussian(Math.sqrt(this.sampleSize * Math.pow((this.responseA - this.responseB) - this.nonInferiorityLimit, 2) / ((this.responseA * (1 - this.responseA)) + (this.responseB * (1 - this.responseB)))) - Stats.uGaussianInverse(1 - this.significanceLevel));

    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }

  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default NonInferiorityBinaryPowerCalculator
