import PrecisionNormalCalculator from "services/calculations/precision/normal/PrecisionNormalCalculator";
import PrecisionBinaryCalculator from "services/calculations/precision/binary/PrecisionBinaryCalculator";
import PrecisionSurvivalCalculator from "services/calculations/precision/survival/PrecisionSurvivalCalculator";

const CALCULATORS = {

  precision: {
    normal:    PrecisionNormalCalculator,
    binary:    PrecisionBinaryCalculator,
    survival:  PrecisionSurvivalCalculator
  },
}

const PowerFactory = function(procedure, endpoint, inputs) {
  if(!CALCULATORS[procedure]) {
    throw new Error(`Invalid procedure ${procedure}`);
  }

  const calculatorClass = CALCULATORS[procedure][endpoint];

  if(calculatorClass) {
    return new calculatorClass(inputs);
  } else {
    throw new Error(`Invalid endpoint ${endpoint}`);
  }
}

export default PowerFactory;
