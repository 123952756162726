import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';
const PrecisionInput = () => (
  <Field
    label="Precision"
    name="precision"
    component={NumberField}
  />
);

export default PrecisionInput;
