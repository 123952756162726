import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";
import _ from 'lodash/math';
class NonInferiorityNormalPowerCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.meanDifference =  parseFloat(calculatorInputs.meanDifference);
    this.nonInferiorityLimit =  parseFloat(calculatorInputs.nonInferiorityLimit);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'meanDifference',
      'nonInferiorityLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {
    let tau = null;
    let df = null;

    if (this._isCrossover()) {
      df = this.sampleSize - 2;
      tau = Math.abs(((this.meanDifference - this.nonInferiorityLimit) * Math.sqrt(this.sampleSize)) / Math.sqrt(2 * (this.populationStandardDeviation ** 2)));
    } else if(this._isParallel()) {
      df = this.sampleSize * (this.allocationRatio + 1) - 2;
      tau = Math.abs(((this.meanDifference-this.nonInferiorityLimit) * Math.sqrt(this.allocationRatio * this.sampleSize)) / Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
    } else {
      throw new Error(`Unknown design: ${this.design}`);
    }

    const t = Stats.studentTInverse(1 - this.significanceLevel, df);

    const power = 1 - new CustomT(t, df, tau).cdfNoncentralT();

    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default NonInferiorityNormalPowerCalculator
