import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const SignificanceInput = () => (
  <Field
    label="Population Standard Deviation"
    name="populationStandardDeviation"
    component={NumberField}
    validate={validate}
  />
);

function validate(value) {
  if ( !validNumber(value, { min: 0.05 }) )
    return "Must be at least 0.05";
}

export default SignificanceInput;
