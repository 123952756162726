import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";

const EquivalenceLimitInput = () => (
  <Field
    label="Equivalence Limit"
    name="equivalenceLimit"
    component={NumberField}
  />
);

export default EquivalenceLimitInput;
