import React from 'react';

const NotFound = () => (
  <div>
    <h1>Page Not Found<sup>404</sup></h1>
    <p>Sorry, this page does not exist.</p>
  </div>
);

export default NotFound;
