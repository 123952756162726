import React from "react";
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <p>
      <small><a href="/acknowledgments" title="View the acknowledgments">See the Acknowledgments</a></small>
    </p>
    <p>
      <small>Built by <a href="https://www.epigenesys.org.uk" title="Visit the epiGenesys website">epiGenesys</a></small>
    </p>
  </footer>
)

export default Footer;
