import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';

class EquivalenceBinaryPowerCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.equivalenceLimit =  parseFloat(calculatorInputs.equivalenceLimit);
    this.responseA =  parseFloat(calculatorInputs.responseA);
    this.responseB =  parseFloat(calculatorInputs.responseB);
    this.design = calculatorInputs.design;
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'equivalenceLimit',
      'responseA',
      'responseB'
    ];
    return meaningfulInputs;
  }


  calculate() {
    let power = null;

    if(this.responseA === this.responseB) {
      power = 2 * Stats.uGaussian(Math.sqrt((this.sampleSize * Math.pow(this.equivalenceLimit,2)) / (2 * this.responseA * (1 - this.responseA)))- Stats.uGaussianInverse(1 - this.significanceLevel)) - 1;
    } else {
      const upperLimit = Math.sqrt((this.sampleSize * Math.pow(((this.responseA - this.responseB) - this.equivalenceLimit), 2)) / (this.responseA * (1 - this.responseA) + this.responseB * (1 - this.responseB)));
      const lowerLimit = Math.sqrt((this.sampleSize * Math.pow(((this.responseA - this.responseB) + this.equivalenceLimit), 2)) / (this.responseA * (1 - this.responseA) + this.responseB * (1 - this.responseB)));
      power = Stats.uGaussian(upperLimit - Stats.uGaussianInverse(1 - this.significanceLevel)) + Stats.uGaussian(lowerLimit - Stats.uGaussianInverse(1 - this.significanceLevel)) - 1;
    }
    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }

  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default EquivalenceBinaryPowerCalculator
