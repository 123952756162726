import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";
import _ from 'lodash/math';


class EquivalenceNormalPowerCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.meanDifference =  parseFloat(calculatorInputs.meanDifference);
    this.equivalenceLimit =  parseFloat(calculatorInputs.equivalenceLimit);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'meanDifference',
      'equivalenceLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {
    if(this._isCrossover()) {
      this.allocationRatio = 1;
    }

    const tauUpper = ((this.meanDifference + this.equivalenceLimit) * Math.sqrt(this.allocationRatio * this.sampleSize)) / (Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
    const tauLower = ((this.meanDifference - this.equivalenceLimit) * Math.sqrt(this.allocationRatio * this.sampleSize)) / (Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));

    let t = null;
    let df = null;

    if (this._isCrossover()) {
      t  = Stats.studentTInverse(1 - this.significanceLevel, this.sampleSize - 2);
      df = this.sampleSize - 2;
    } else if(this._isParallel()) {
      t  = Stats.studentTInverse(1 - this.significanceLevel, this.sampleSize * (this.allocationRatio + 1) - 2);
      df = this.sampleSize * (this.allocationRatio + 1) - 2;
    } else {
      throw new Error(`Unknown design: ${this.design}`);
    }

    const lowerTDistribution = new CustomT(-t, df, tauLower).cdfNoncentralT();

    const upperTDistribution = new CustomT(t, df, tauUpper).cdfNoncentralT();
    const power = lowerTDistribution - upperTDistribution;

    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }
  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default EquivalenceNormalPowerCalculator
