import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import { getCalculatorDefaultValues, getCalculatorInputList } from 'containers/Calculator';

import SelectField from 'components/forms/SelectField';
import SubmitButton from 'components/forms/SubmitButton';

class CalculatorTypeForm extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { handleSubmit } = this.props
    return (
      <React.Fragment>
        <h1 className="h5">Calculate the ideal sample size or power for your clinical trial</h1>
        <form onSubmit={handleSubmit}>
          <Field
            label="Procedure"
            name="procedure"
            component={SelectField}
            options={[['superiority', 'Superiority'], ['equivalence', 'Equivalence'], ['non-inferiority', 'Non inferiority'], ['bioequivalence', 'Bioequivalence'], ['precision', 'Precision']]}
          />
          <Field
            label="Design"
            name="design"
            component={SelectField}
            options={[['crossover','Crossover'], ['parallel', 'Parallel']]}
          />
          <Field
            label="Endpoint"
            name="endpoint"
            component={SelectField}
            options={generateEndpoints(this.props.calculatorInputs.design, this.props.calculatorInputs.procedure)}
          />
          <Field
            label="Calculation"
            name="calculation"
            component={SelectField}
            options={generateCalculations(this.props.calculatorInputs.procedure)}
          />
          <SubmitButton>Next</SubmitButton>
        </form>
      </React.Fragment>
    )
  }
}

function generateEndpoints(design, procedure) {
  if (procedure === 'bioequivalence') {
    return([['normal', 'Normal']])
  } else if (design !== 'crossover') {
    return([['normal', 'Normal'],['binary', 'Binary'], ['survival', 'Survival']])
  } else {
    return([['normal', 'Normal'],['binary', 'Binary']])
  }
}

function generateCalculations(procedure) {
  if (procedure === 'precision') {
    return([['sampleSize', 'Sample Size'], ['precision', 'Precision']])
  } else {
    return([['sampleSize', 'Sample Size'], ['power', 'Power']])
  }
}

// mapStateToProps so we can get current form values from the store using formValueSelector
const selector = formValueSelector("CalculatorForm");
function mapStateToProps(state){
  return {
    calculatorInputs: selector(state, ...getCalculatorInputList())
  }
}
CalculatorTypeForm = connect(mapStateToProps)(CalculatorTypeForm);


CalculatorTypeForm = reduxForm({
  form: 'CalculatorForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(CalculatorTypeForm)

CalculatorTypeForm = connect(
  state => ({ initialValues: getCalculatorDefaultValues() })
)(CalculatorTypeForm)

export default CalculatorTypeForm;

// ES6 SHORTHAND: { validate: validate } can be written as { validate }
