import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";

class NonInferiorityNormalSampleSizeCalculator {

  constructor (calculatorInputs) {
    this.power =  parseFloat(calculatorInputs.power);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.meanDifference =  parseFloat(calculatorInputs.meanDifference);
    this.nonInferiorityLimit =  parseFloat(calculatorInputs.nonInferiorityLimit);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'meanDifference',
      'nonInferiorityLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {
    let sampleSize = 2;
    let solutionReached = false;

    while(!solutionReached) {
      sampleSize += 1;

      let tau = null;
      let df = null;

      if (this._isCrossover()) {
        df = sampleSize - 2;
        tau = Math.abs(((this.meanDifference - this.nonInferiorityLimit) * Math.sqrt(sampleSize)) / Math.sqrt(2 * (this.populationStandardDeviation ** 2)));
      } else if(this._isParallel()) {
        df = sampleSize * (this.allocationRatio + 1) - 2;
        tau = Math.abs(((this.meanDifference-this.nonInferiorityLimit) * Math.sqrt(this.allocationRatio * sampleSize)) / Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
      } else {
        throw new Error(`Unknown design: ${this.design}`);
      }

      const t = Stats.studentTInverse(1 - this.significanceLevel, df);

      const currentPower = 1 - new CustomT(t, df, tau).cdfNoncentralT();

      if (currentPower >= this.power) {
        solutionReached = true;
      }
    }

    if(this._isCrossover()) {
      return { sampleSize: sampleSize };
    }

    if(this._isParallel()) {
      const sampleSizeGroup2 = Math.ceil(sampleSize * this.allocationRatio);
      const totalSampleSize  = sampleSizeGroup2 + sampleSize;
      return { sampleSizeGroup1: sampleSize, sampleSizeGroup2: sampleSizeGroup2 };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default NonInferiorityNormalSampleSizeCalculator
