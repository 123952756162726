import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as Sentry from "@sentry/browser";
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';

import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css";

import 'bootstrap/dist/css/bootstrap.css';

import rootReducer from './reducers';
import App from './components/App';
import ErrorBoundary from "./components/ErrorBoundary";

import registerServiceWorker from './registerServiceWorker';

// If we don't set autoAddCss to false and include it manually it will violate the CSP
config.autoAddCss = false

const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware()
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://82589726812a49b9822b4ad1ccda3d87@sentry.epigenesys.org.uk/15' })
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root'),
)
registerServiceWorker();
