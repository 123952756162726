import React from "react";
import methods_pdf from './methods.pdf';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faBars } from '@fortawesome/free-solid-svg-icons';

import './Navbar.css';

const Navbar = () => (
  <nav className="navbar sticky-top navbar-expand-sm navbar-dark bg-dark">
    <a className="navbar-brand" href="/">
      <span className="brand-icon text-warning"><FontAwesomeIcon icon={faChartPie} size="lg" /></span>
      Samp<strong>Size</strong>
    </a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <FontAwesomeIcon icon={faBars} />
    </button>
    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div className="navbar-nav ml-auto">
        <NavLink exact className="nav-item nav-link" to="/">Calculator</NavLink>
        <a href={methods_pdf} className='nav-item nav-link'>Methods</a>
        <NavLink className="nav-item nav-link" to="/contact">Contact</NavLink>
      </div>
    </div>
  </nav>
);

export default Navbar;
