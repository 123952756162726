import { studentt, normal, ibeta, gammafn, exponential } from 'jStat';

export function studentT(p, dof) {
  return studentt.cdf(p, dof);
}

export function studentTInverse(p, dof) {
  return studentt.inv(p, dof);
}

export function uGaussian(p) {
  return normal.cdf(p, 0, 1);
}

export function uGaussianInverse(p) {
  return normal.inv(p, 0, 1);
}

export function betaInc(a, b, x) {
  return ibeta(x, a, b);
}

export function gamma(x) {
  return gammafn(x);
}

export function exponentialP(x, rate) {
  return exponential.cdf(x, rate);
}
