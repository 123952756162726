import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";

const SampleSizeInput = () => (
  <Field
    label="Sample Size"
    name="sampleSize"
    component={NumberField}
  />
);

export default SampleSizeInput;
