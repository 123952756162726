import React from 'react';

const Acknowledgments = () => (
  <div>
    <h1 className="h3">Acknowledgments</h1>
    <p>The App SampSize was developed with a assistance of a Crowdfunding appeal and the support of NIHR CTU Support Funding Supporting efficient / innovative delivery of NIHR research. We are grateful for all the support.</p>
    <p>The App is developed to assist in the design of clinical trials and is also used in the teaching of students at The University of Sheffield on the MSc in Statistics with Medical Applications and the Masters in Public Health.</p>
  </div>
);

export default Acknowledgments;
