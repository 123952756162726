import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";
import _ from 'lodash/math';


class BioequivalenceNormalPowerCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.ratioOfTreatments =  parseFloat(calculatorInputs.ratioOfTreatments);
    this.bioequivalenceLimit =  parseFloat(calculatorInputs.bioequivalenceLimit);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'ratioOfTreatments',
      'bioequivalenceLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {

    let df = null;
    let tauUpper = null;
    let tauLower = null;
    if (this._isCrossover()) {
      tauUpper = (Math.sqrt(this.sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 - this.bioequivalenceLimit)) / Math.sqrt(2 * (this.populationStandardDeviation ** 2)));
      tauLower = (Math.sqrt(this.sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 / (1 - this.bioequivalenceLimit))) / Math.sqrt(2 * (this.populationStandardDeviation ** 2)));
      df = this.sampleSize - 2;
    } else if(this._isParallel()) {
      tauUpper = (Math.sqrt(this.allocationRatio * this.sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 - this.bioequivalenceLimit)) / Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
      tauLower = (Math.sqrt(this.allocationRatio * this.sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 / (1 - this.bioequivalenceLimit))) / Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
      df = this.sampleSize * (this.allocationRatio + 1) - 2;

    } else {
      throw new Error(`Unknown design: ${this.design}`);
    }
    const t = Stats.studentTInverse(1 - this.significanceLevel, df);
    const lowerTDistribution = new CustomT(-t, df, tauLower).cdfNoncentralT();
    const upperTDistribution = new CustomT(t, df, tauUpper).cdfNoncentralT();

    const power = lowerTDistribution - upperTDistribution;

    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default BioequivalenceNormalPowerCalculator
