import PowerFactory      from 'services/factories/PowerFactory';
import PrecisionFactory  from 'services/factories/PrecisionFactory';
import SampleSizeFactory from 'services/factories/SampleSizeFactory';

class Calculation {

  constructor(calculatorInputs) {
    this.calculatorInputs = calculatorInputs;
    this.calculator = this._buildCalculator()
  }

  meaningfulInputs() {
    return this.calculator.getMeaningfulInputs();
  }

  calculateOutputs() {
    return this.calculator.calculate();
  }

  _buildCalculator() {
    const calculation = this.calculatorInputs.calculation;
    const procedure   = this.calculatorInputs.procedure;
    const endpoint    = this.calculatorInputs.endpoint;
    const inputs      = this.calculatorInputs;

    switch (calculation) {
      case "power":
        return PowerFactory(procedure, endpoint, inputs);
      case "sampleSize":
        return SampleSizeFactory(procedure, endpoint, inputs);
      case "precision":
        return PrecisionFactory(procedure, endpoint, inputs);
      default:
        throw new Error(`Invalid calculation ${calculation}`)
    }
  }

}

export default Calculation;
