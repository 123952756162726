import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const PowerInput = () => (
  <Field
    label="Power"
    name="power"
    component={NumberField}
    validate={validate}
  />
);

function validate(value) {
  if ( !validNumber(value, { min: 0, max: 0.99 }) )
    return "Must be between 0 and 0.99";
}

export default PowerInput;
