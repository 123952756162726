import * as Stats from 'services/calculations/stats';

class NonInferioritySurvivalSampleSizeCalculator {

  constructor (calculatorInputs){
    this.nonInferiorityLimit = parseFloat(calculatorInputs.nonInferiorityLimit);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.power = parseFloat(calculatorInputs.power);
    this.hazardRatio = parseFloat(calculatorInputs.hazardRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'nonInferiorityLimit',
      'hazardRatio'
    ];
    return meaningfulInputs;
  }

  calculate() {
    const numerator = 2 * ((Stats.uGaussianInverse(1 - (this.significanceLevel)) + Stats.uGaussianInverse(this.power)) ** 2);
    const denominator = ((Math.log(this.hazardRatio) - Math.log(this.nonInferiorityLimit)) ** 2);
    return { events: Math.ceil(numerator/denominator) }
  }
}

export default NonInferioritySurvivalSampleSizeCalculator;
