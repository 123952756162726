import * as Stats from 'services/calculations/stats';
import _ from 'lodash';

class PrecisionBinaryCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.significance =  parseFloat(calculatorInputs.significance);
    this.averageAnticipatedResponse =  parseFloat(calculatorInputs.averageAnticipatedResponse);
    this.design = calculatorInputs.design;
  }

  getMeaningfulInputs() {
    const meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'significance',
      'averageAnticipatedResponse'
    ];
    return meaningfulInputs;
  }


  calculate() {

    const precision = Math.sqrt(
      (
        2 * this.averageAnticipatedResponse *
        ( 1 - this.averageAnticipatedResponse ) * Math.pow( Stats.uGaussianInverse( 1 - this.significanceLevel / this.significance), 2 )
      ) / this.sampleSize
    )

    const result = { precision:  _.round(precision, 2) }

    if( this._isParallel()){
      result['sampleSizePerGroup'] = this.sampleSize
    }

    return result
  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default PrecisionBinaryCalculator
