import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';

class NonInferioritySurvivalPowerCalculator {

  constructor (calculatorInputs){
    this.nonInferiorityLimit = parseFloat(calculatorInputs.nonInferiorityLimit);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.sampleSize = parseFloat(calculatorInputs.sampleSize);
    this.hazardRatio = parseFloat(calculatorInputs.hazardRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'nonInferiorityLimit',
      'hazardRatio'
    ];
    return meaningfulInputs;
  }

  calculate() {
    const power = Stats.uGaussian(Math.sqrt((Math.abs((Math.log(this.hazardRatio) - Math.log(this.nonInferiorityLimit))** 2) * this.sampleSize)/ 2) - Stats.uGaussianInverse(1 - this.significanceLevel))
    return { power:  _.round(power, 2) }
  }
}

export default NonInferioritySurvivalPowerCalculator;
