import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";

const RatioOfTreatmentsInput = () => (
  <Field
    label="Ratio of the Treatments"
    name="ratioOfTreatments"
    component={NumberField}
  />
);

export default RatioOfTreatmentsInput;
