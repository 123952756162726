import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';

class SuperioritySurvivalPowerCalculator {

  constructor (calculatorInputs){
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.significance = parseFloat(calculatorInputs.significance);
    this.sampleSize = parseFloat(calculatorInputs.sampleSize);
    this.hazardRatio = parseFloat(calculatorInputs.hazardRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'significance',
      'hazardRatio'
    ];
    return meaningfulInputs;
  }

  calculate() {
    const power = Stats.uGaussian(Math.sqrt(((Math.log(this.hazardRatio) ** 2) * this.sampleSize)/ 2) - Stats.uGaussianInverse(1 - this.significanceLevel / this.significance))
    return { power:  _.round(power, 2) }
  }
}

export default SuperioritySurvivalPowerCalculator;
