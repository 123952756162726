import { Field } from "redux-form";
import React from "react";

import NumberField from "components/forms/NumberField";

const BioequivalenceLimitInput = () => (
  <Field
    label="Bioequivalence Limit"
    name="bioequivalenceLimit"
    component={NumberField}
  />
);

export default BioequivalenceLimitInput;
