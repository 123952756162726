import * as Stats from 'services/calculations/stats';

class SuperiorityBinarySampleSizeCalculator {

  constructor (calculatorInputs) {
    this.design = calculatorInputs.design;
    this.power = parseFloat(calculatorInputs.power);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.significance = calculatorInputs.significance;
    this.continuityCorrection = (String(calculatorInputs.continuityCorrection) == "true")
    this.responseA = parseFloat(calculatorInputs.responseA);
    this.responseB = parseFloat(calculatorInputs.responseB);
    this.allocationRatio = parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'significance',
      'continuityCorrection',
      'responseA',
      'responseB',
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }

  calculate() {

    const piBar = (this.responseA + this.responseB)/2;

    let nA = ((Stats.uGaussianInverse(1 - this.significanceLevel / this.significance) * Math.sqrt(2 * piBar * (1 - piBar)) + Stats.uGaussianInverse(this.power) * Math.sqrt(this.responseA * (1 - this.responseA) + this.responseB * (1 - this.responseB))) ** 2) / ((this.responseA - this.responseB) ** 2);
    if (this.continuityCorrection) {
      nA = Math.ceil(nA);
      const delta = Math.abs(this.responseA - this.responseB);
      nA = nA / 4 * ((1 + Math.sqrt(1 + (4 / (nA * delta)))) ** 2);
      nA = Math.ceil(nA);
    }

    let nB = nA;


    if (this._isCrossover()) {
      let nDisc =  nA * (this.responseA * (1 - this.responseB) + this.responseB * (1 - this.responseA));
      return { totalSampleSize: Math.ceil(nA), discordantSampleSize: Math.ceil(nDisc) }
    } else if (this._isParallel()) {
      return { sampleSizeGroup1: Math.ceil(nA), sampleSizeGroup2: Math.ceil(nB) };
    }

  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }
}

export default SuperiorityBinarySampleSizeCalculator;
