import SuperiorityNormalPowerCalculator    from "services/calculations/superiority/normal/SuperiorityNormalPowerCalculator";
import SuperiorityBinaryPowerCalculator    from "services/calculations/superiority/binary/SuperiorityBinaryPowerCalculator";
import SuperioritySurvivalPowerCalculator    from "services/calculations/superiority/survival/SuperioritySurvivalPowerCalculator";

import EquivalenceNormalPowerCalculator    from "services/calculations/equivalence/normal/EquivalenceNormalPowerCalculator";
import EquivalenceBinaryPowerCalculator    from "services/calculations/equivalence/binary/EquivalenceBinaryPowerCalculator";
import EquivalenceSurvivalPowerCalculator  from "services/calculations/equivalence/survival/EquivalenceSurvivalPowerCalculator";


import NonInferiorityNormalPowerCalculator from "services/calculations/non_inferiority/normal/NonInferiorityNormalPowerCalculator";
import NonInferiorityBinaryPowerCalculator from "services/calculations/non_inferiority/binary/NonInferiorityBinaryPowerCalculator";
import NonInferioritySurvivalPowerCalculator from "services/calculations/non_inferiority/survival/NonInferioritySurvivalPowerCalculator";

import BioequivalenceNormalPowerCalculator from "services/calculations/bioequivalence/normal/BioequivalenceNormalPowerCalculator";

const CALCULATORS = {

  superiority: {
    normal: SuperiorityNormalPowerCalculator,
    binary: SuperiorityBinaryPowerCalculator,
    survival: SuperioritySurvivalPowerCalculator
  },
  equivalence: {
    normal: EquivalenceNormalPowerCalculator,
    binary: EquivalenceBinaryPowerCalculator,
    survival: EquivalenceSurvivalPowerCalculator
  },
  "non-inferiority": {
    normal: NonInferiorityNormalPowerCalculator,
    binary: NonInferiorityBinaryPowerCalculator,
    survival: NonInferioritySurvivalPowerCalculator
  },
  bioequivalence: {
    normal: BioequivalenceNormalPowerCalculator

  }
}

const PowerFactory = function(procedure, endpoint, inputs) {
  if(!CALCULATORS[procedure]) {
    throw new Error(`Invalid procedure ${procedure}`);
  }

  const calculatorClass = CALCULATORS[procedure][endpoint];

  if(calculatorClass) {
    return new calculatorClass(inputs);
  } else {
    throw new Error(`Invalid endpoint ${endpoint}`);
  }
}

export default PowerFactory;
