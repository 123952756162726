import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";

class SuperiorityNormalSampleSizeCalculator {

  constructor (calculatorInputs) {
    this.design = calculatorInputs.design;
    this.power = parseFloat(calculatorInputs.power);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel)
    this.significance = calculatorInputs.significance;
    this.meanDifference = parseFloat(calculatorInputs.meanDifference);
    this.populationStandardDeviation = parseFloat(calculatorInputs.populationStandardDeviation);
    this.allocationRatio = parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'significance',
      'meanDifference',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }

  calculate() {
    let sampleSize = 2;
    let solutionReached = false;

    while(!solutionReached) {
      sampleSize += 1;

      let t = null;
      let df = null;
      let mu = null;

      if(this._isCrossover()) {
        t  = Stats.studentTInverse(1 - this.significanceLevel/this.significance, sampleSize - 2);
        df = sampleSize - 2;
        mu = Math.sqrt(sampleSize * (this.meanDifference ** 2) / (2 * (this.populationStandardDeviation ** 2)));
      } else if(this._isParallel()) {
        t  = Stats.studentTInverse(1 - this.significanceLevel/this.significance, sampleSize * (this.allocationRatio + 1) - 2);
        df = sampleSize * (this.allocationRatio + 1) - 2;
        mu = Math.sqrt((this.allocationRatio * sampleSize * (this.meanDifference ** 2)) / ((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
      } else {
        throw new Error(`Unknown design: ${this.design}`);
      }

      const customTDistribution = new CustomT(t, df, mu);
      const currentPower = 1 - customTDistribution.cdfNoncentralT()

      if (currentPower > this.power) {
        solutionReached = true;
      }
    }

    if(this._isCrossover()) {
      return { sampleSize: sampleSize };
    }

     if(this._isParallel()) {
      const sampleSizeGroup2 = Math.ceil(sampleSize * this.allocationRatio);
      const totalSampleSize  = sampleSizeGroup2 + sampleSize;
      return { sampleSizeGroup1: sampleSize, sampleSizeGroup2: sampleSizeGroup2 };
    }



  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }
}

export default SuperiorityNormalSampleSizeCalculator;
