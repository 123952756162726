import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import { getCalculatorInputList } from './Calculator';
import Calculation from 'services/Calculation';
import Results from 'components/Results';
import PreviousButton from 'components/forms/PreviousButton';
import SubmitButton from 'components/forms/SubmitButton';

import * as Sentry from "@sentry/browser";

class CalculatorResults extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const { handleSubmit, previousStep, calculatorInputs } = this.props;

    Sentry.addBreadcrumb({
      message: "Calculate Results",
      category: "action",
      data: {
        previous_step: previousStep,
        calculator_inputs: calculatorInputs
      },
    });

    const calculation = new Calculation(calculatorInputs);
    const resultsInputs  = _.pick(calculatorInputs, calculation.meaningfulInputs());
    const resultsOutputs = calculation.calculateOutputs();

    return (
      <form onSubmit={handleSubmit}>
        <Results
          procedure={calculatorInputs.procedure}
          design={calculatorInputs.design}
          endpoint={calculatorInputs.endpoint}
          inputs={resultsInputs}
          outputs={resultsOutputs}
        />
        <SubmitButton>Start again</SubmitButton>
        <PreviousButton onClick={previousStep}>Change inputs</PreviousButton>
      </form>
    )
  }
}

function validate(values){
  const errors = {};
  // errors.someField = "Some error."
  return errors;
}

CalculatorResults = reduxForm({
  form: "CalculatorForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(CalculatorResults);

// mapStateToProps so we can get current form values from the store using formValueSelector
const selector = formValueSelector("CalculatorForm");
function mapStateToProps(state){
  return {
    calculatorInputs: selector(state, ...getCalculatorInputList())
  }
}
CalculatorResults = connect(mapStateToProps)(CalculatorResults);

export default CalculatorResults;
