import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import React, { Component } from "react";

import { getCalculatorInputList } from 'containers/Calculator';
import validNumber from 'services/validators/number';

import AllocationRatioInput from 'containers/calculator_inputs/AllocationRatioInput';
import BioequivalenceLimitInput from 'containers/calculator_inputs/BioequivalenceLimitInput';
import ContinuityCorrectionInput from 'containers/calculator_inputs/ContinuityCorrectionInput';
import EquivalenceLimitInput from 'containers/calculator_inputs/EquivalenceLimitInput';
import HazardRatioInput from 'containers/calculator_inputs/HazardRatioInput';
import MeanDifferenceInput from 'containers/calculator_inputs/MeanDifferenceInput';
import NonInferiorityLimitInput from 'containers/calculator_inputs/NonInferiorityLimitInput';
import PopulationStandardDeviationInput from 'containers/calculator_inputs/PopulationStandardDeviationInput';
import PowerInput from 'containers/calculator_inputs/PowerInput';
import PrecisionInput from 'containers/calculator_inputs/PrecisionInput';
import PreviousButton from 'components/forms/PreviousButton';
import RatioOfTreatmentsInput from 'containers/calculator_inputs/RatioOfTreatmentsInput';
import SampleSizeInput from 'containers/calculator_inputs/SampleSizeInput';
import SignificanceInput from 'containers/calculator_inputs/SignificanceInput';
import SignificanceLevelInput from 'containers/calculator_inputs/SignificanceLevelInput';
import SubmitButton from 'components/forms/SubmitButton';
import ResponseAnticipatedInput from 'containers/calculator_inputs/ResponseAnticipatedInput';
import AverageAnticipatedResponseInput from 'containers/calculator_inputs/AverageAnticipatedResponseInput';

class CalculatorInputsForm extends Component {

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  formValues(inputs) {
    const { calculatorInputs } = this.props

    let fields = null;
    if (calculatorInputs.procedure === 'superiority') {
      fields = setupSuperiority(inputs)
    } else if (calculatorInputs.procedure === 'equivalence') {
      fields = setupEquivalence(inputs)
    } else if (calculatorInputs.procedure === 'non-inferiority') {
      fields = setupNonInferiority(inputs)
    } else if (calculatorInputs.procedure === 'bioequivalence') {
      fields = setupBioequivalence(inputs)
    } else if (calculatorInputs.procedure === 'precision') {
      fields = setupPrecision(inputs)
    }
    return(fields)
  }

  render() {
    const { handleSubmit, previousStep, calculatorInputs } = this.props

    return (
      <React.Fragment>
        <h1 className="h5">Enter your inputs</h1>
        <form onSubmit={handleSubmit}>
          { this.formValues({design: calculatorInputs.design, endpoint: calculatorInputs.endpoint, calculation: calculatorInputs.calculation}) }
          <div>
            <SubmitButton>Calculate</SubmitButton>
            <PreviousButton onClick={previousStep}>Change calculation type</PreviousButton>
          </div>
        </form>
      </React.Fragment>
    )
  }
}


function validate(values) {
  const errors = {};

  if(values.meanDifference != 0) {
    if ((validNumber(values.populationStandardDeviation) && validNumber(values.meanDifference)) && ((values.populationStandardDeviation / values.meanDifference) > 100))
      errors.populationStandardDeviation = "Cannot do calculations where the Standard Deviation is greater than 100 times the Mean Difference"

    if ( (values.procedure === 'equivalence') && Math.abs(values.meanDifference) >= Math.abs(values.equivalenceLimit) )
      errors.meanDifference = "The absolute value of the difference between treatments cannot be greater than or equal to the absolute value of Equivalence Limit"
  }
  return errors;
}

// mapStateToProps so we can get current form values from the store using formValueSelector
const selector = formValueSelector("CalculatorForm");
function mapStateToProps(state){
  return {
    calculatorInputs: selector(state, ...getCalculatorInputList())
  }
}
CalculatorInputsForm = connect(mapStateToProps)(CalculatorInputsForm);

function setupSuperiority(inputs) {
  let fields = null;
  if (inputs.endpoint === 'survival') {
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <SignificanceInput />
        <HazardRatioInput />
      </React.Fragment>
  } else if(inputs.endpoint === 'binary') {
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <SignificanceInput />
        <ContinuityCorrectionInput />
        <ResponseAnticipatedInput treatment_identifier='A' />
        <ResponseAnticipatedInput treatment_identifier='B' />
      </React.Fragment>
  } else if(inputs.endpoint === 'normal'){
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <SignificanceInput />
        <MeanDifferenceInput />
        <PopulationStandardDeviationInput />
        <AllocationRatioInput design={inputs.design} />
      </React.Fragment>
  }
  return fields;
}

function setupEquivalence(inputs) {
  let fields = null;
  if (inputs.endpoint === 'survival') {
    fields =
      <React.Fragment>
        <EquivalenceLimitInput />
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <HazardRatioInput />
      </React.Fragment>
  } else if(inputs.endpoint === 'binary') {
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <EquivalenceLimitInput />
        <ResponseAnticipatedInput treatment_identifier='A' />
        <ResponseAnticipatedInput treatment_identifier='B' />
      </React.Fragment>
  } else if(inputs.endpoint === 'normal'){
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <MeanDifferenceInput />
        <EquivalenceLimitInput />
        <PopulationStandardDeviationInput />
        <AllocationRatioInput design={inputs.design} />
      </React.Fragment>
  }
  return fields;
}

function setupNonInferiority(inputs){
  let fields = null;
  if (inputs.endpoint === 'survival') {
    fields =
      <React.Fragment>
        <NonInferiorityLimitInput />
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <HazardRatioInput />
      </React.Fragment>
  } else if(inputs.endpoint === 'binary') {
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <NonInferiorityLimitInput />
        <ResponseAnticipatedInput treatment_identifier='A' />
        <ResponseAnticipatedInput treatment_identifier='B' />
      </React.Fragment>
  } else if(inputs.endpoint === 'normal'){
    fields =
      <React.Fragment>
        {calculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <MeanDifferenceInput />
        <NonInferiorityLimitInput />
        <PopulationStandardDeviationInput />
        <AllocationRatioInput design={inputs.design} />
      </React.Fragment>
  }
  return fields;
}

function setupBioequivalence(inputs) {
  let fields = null;
  fields = <React.Fragment>
            {calculationTypeField(inputs.calculation)}
            <SignificanceLevelInput />
            <RatioOfTreatmentsInput />
            <BioequivalenceLimitInput />
            <PopulationStandardDeviationInput />
            <AllocationRatioInput design={inputs.design} />
           </React.Fragment>
  return fields;
}

function setupPrecision(inputs){
  let fields = null;
  if (inputs.endpoint === 'survival') {
    fields =
      <React.Fragment>
        {precisionCalculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <SignificanceInput />
      </React.Fragment>
  } else if(inputs.endpoint === 'binary') {
    fields =
      <React.Fragment>
        {precisionCalculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <SignificanceInput />
        <AverageAnticipatedResponseInput />
      </React.Fragment>

  } else if(inputs.endpoint === 'normal'){
    fields =
      <React.Fragment>
        {precisionCalculationTypeField(inputs.calculation)}
        <SignificanceLevelInput />
        <SignificanceInput />
        <PopulationStandardDeviationInput />
        <AllocationRatioInput design={inputs.design} />
      </React.Fragment>
 }
 return fields;
}

function precisionCalculationTypeField(calculation){
  if (calculation === 'sampleSize') {
    return <PrecisionInput />;
  } else if (calculation === 'precision') {
    return <SampleSizeInput />;
  }
}

function calculationTypeField(calculation){
  if (calculation === 'sampleSize') {
    return <PowerInput />;
  } else if (calculation === 'power') {
    return <SampleSizeInput />;
  }
}

export default reduxForm({
  form: 'CalculatorForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate
})(CalculatorInputsForm)
