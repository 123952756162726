import * as Stats from 'services/calculations/stats';

class PrecisionSurvivalSampleSizeCalculator {

  constructor (calculatorInputs){
    this.precision = parseFloat(calculatorInputs.precision);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.significance = parseFloat(calculatorInputs.significance);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'precision',
      'significanceLevel',
    ];
    return meaningfulInputs;
  }

  calculate() {
    const numerator = 2 * ((Stats.uGaussianInverse(1 - (this.significanceLevel / this.significance)) ** 2));
    const denominator = (Math.log(1 - this.precision) ** 2);
    return { events: Math.ceil(numerator/denominator) }
  }
}

export default PrecisionSurvivalSampleSizeCalculator;
