import React from 'react';
import './fields.css';

const SelectField = function(props) {
  const { meta: { touched, error }, options } = props;
  const inputClass = `form-control ${touched && error ? 'is-invalid' : ''}`;
  const inputId = props.input.name;

  return(
    <div className='form-group'>
      <label htmlFor={inputId}>{props.label}</label>
      <select
        {...props.input}
        id={inputId}
        className={inputClass}
      >
        {options.map(renderOptions)}
      </select>
      <div className="text-help text-danger">
        {touched ? error : ''}
      </div>
    </div>
  )
}

function renderOptions(option) {
  const hasSeparateValues = Array.isArray(option);
  const value = hasSeparateValues ? option[0] : option;
  const display = hasSeparateValues ? option[1] : option;
  return(
    <option key={value} value={value}>{display}</option>
  )
}

export default SelectField;
