import React from "react";
import _ from 'lodash';
import { CALCULATOR_INPUTS } from "containers/Calculator";
import "./Results.css"

const Results = function(props) {
  const { procedure, design, inputs, outputs, endpoint } = props;

  return (
    <div className="text-center">
      <h1 className="h5"><span className="result-title">{procedure}</span> result for a {design} group trial with {endpoint} data:</h1>
      <div className="circle-badge-list mb-3">
        {renderOutputList(outputs)}
      </div>

      <p><strong>Based on the inputs:</strong></p>
      <dl className="small">
        {renderInputList(inputs)}
      </dl>
    </div>
  )
}

function renderOutputList(collection) {
  return _.map(collection, function(value, name){
    return(
      <div key={name} className="circle-badge">
        <div className="circle-badge-circle rounded-circle bg-warning">
          <span className={name}>{value}</span>
        </div>
        <h3 className="circle-badge-title">{CALCULATOR_INPUTS[name].displayName}</h3>
      </div>
    )
  })
}

function renderInputList(collection) {
  return _.map(collection, function(value, name){
    return(
      <React.Fragment key={name}>
        <dt>{CALCULATOR_INPUTS[name].displayName}</dt>
        <dd className={name}>{value}</dd>
      </React.Fragment>
    )
  })
}

export default Results;
