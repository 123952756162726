import _ from 'lodash';
import React, { Component } from "react";
import { connect } from "react-redux";
import { destroy } from "redux-form";

import CalculatorTypeForm from "containers/CalculatorTypeForm";
import CalculatorInputsForm from "containers/CalculatorInputsForm";
import CalculatorResults from "containers/CalculatorResults";

export const CALCULATOR_INPUTS = {
  procedure:                   { defaultValue: "superiority", displayName: "Procedure" },
  design:                      { defaultValue: "parallel",    displayName: "Parallel" },
  endpoint:                    { defaultValue: "normal",      displayName: "Endpoint" },
  calculation:                 { defaultValue: "sampleSize",  displayName: "Calculation" },
  continuityCorrection:        { defaultValue: null,          displayName: "Continuity Correction" },
  power:                       { defaultValue: 0.9,           displayName: "Power" },
  significanceLevel:           { defaultValue: 0.05,          displayName: "Significance Level" },
  significance:                { defaultValue: 2,             displayName: "Significance" },
  meanDifference:              { defaultValue: null,          displayName: "Mean Difference" },
  populationStandardDeviation: { defaultValue: null,          displayName: "Population Standard Deviation" },
  sampleSize:                  { defaultValue: null,          displayName: "Sample Size"},
  sampleSizePerGroup:          { defaultValue: null,          displayName: "Sample Size / Group"},
  allocationRatio:             { defaultValue: 1,             displayName: "Allocation Ratio"},
  discordantSampleSize:        { defaultValue: null,          displayName: "Discordant Sample Size"},
  totalSampleSize:             { defaultValue: null,          displayName: "Total"},
  sampleSizeGroup1:            { defaultValue: null,          displayName: "Group 1"},
  sampleSizeGroup2:            { defaultValue: null,          displayName: "Group 2"},
  events:                      { defaultValue: null,          displayName: "Events per arm"},
  hazardRatio:                 { defaultValue: null,          displayName: "Hazard Ratio"},
  equivalenceLimit:            { defaultValue: null,          displayName: "Equivalence Limit"},
  nonInferiorityLimit:         { defaultValue: null,          displayName: "Non Inferiority Limit"},
  ratioOfTreatments:           { defaultValue: null,          displayName: "Ratio Of Treatments"},
  bioequivalenceLimit:         { defaultValue: null,          displayName: "Bioequivalence Limit"},
  precision:                   { defaultValue: 0.9,           displayName: "Precision"},
  responseA:                   { defaultValue: null,          displayName: "Response Anticipated Treat. A" },
  responseB:                   { defaultValue: null,          displayName: "Response Anticipated Treat. B" },
  averageAnticipatedResponse:  { defaultValue: null,          displayName: "Average Anticipated Response" },
}

export const getCalculatorDefaultValues = function() {
  return _.reduce(CALCULATOR_INPUTS, function(result, input, inputKey) {
    result[inputKey] = input.defaultValue;
    return result;
  }, {});
}
export const getCalculatorInputList = function() {
  return Object.keys(CALCULATOR_INPUTS);
}

class Calculator extends Component {

  constructor(props) {
    super(props)
    this.state = { step: 1 }
  }

  nextStep = () => {
    this.setState({ step: this.state.step + 1 });
  }

  previousStep = () => {
    this.setState({ step: this.state.step - 1 });
  }

  reset = () => {
    // Tell redux-form to cleart all the the form state
    this.props.destroy("CalculatorForm");
    this.setState({ step: 1 });
  }

  render() {
    const { step } = this.state

    return(
      <React.Fragment>
        {
          step === 1 &&
          <CalculatorTypeForm onSubmit={this.nextStep} previousStep={this.previousStep} />
        }
        {
          step === 2 &&
          <CalculatorInputsForm onSubmit={this.nextStep} previousStep={this.previousStep}  />
        }
        {
          step === 3 &&
          <CalculatorResults onSubmit={this.reset} previousStep={this.previousStep} />
        }
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = { destroy };

export default connect(null, mapDispatchToProps)(Calculator);
