import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const SignificanceLevelInput = () => (
  <Field
    label="Significance Level"
    name="significanceLevel"
    component={NumberField}
    validate={validate}
  />
);

function validate(value) {
  if ( !validNumber(value, { min: 0.001, max: 0.99 }) )
    return "Must be between 0.001 and 0.99";
}

export default SignificanceLevelInput;
