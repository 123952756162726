import * as Stats from 'services/calculations/stats';
import _ from 'lodash';

class PrecisionSurvivalCalculator {

  constructor (calculatorInputs){
    this.sampleSize = calculatorInputs.sampleSize;
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.significance = parseFloat(calculatorInputs.significance);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
    ];
    return meaningfulInputs;
  }

  calculate() {
    const inner = (Stats.uGaussianInverse(1 - this.significanceLevel/this.significance) ** 2) / this.sampleSize
    const outer = 1 - Math.exp(-Math.sqrt(2 * inner))
    return { precision: _.round(outer, 2) }
  }
}

export default PrecisionSurvivalCalculator;
