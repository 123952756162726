import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const HazardRatioInput = () => (
  <Field
    label="Hazard Ratio"
    name="hazardRatio"
    component={NumberField}
    validate={validate}
  />
);

function validate(value) {
  if ( !validNumber(value) )
    return "Must be a number"
}

export default HazardRatioInput;
