import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";

class BioequivalenceNormalSampleSizeCalculator {

  constructor (calculatorInputs) {
    this.power =  parseFloat(calculatorInputs.power);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.ratioOfTreatments =  parseFloat(calculatorInputs.ratioOfTreatments);
    this.bioequivalenceLimit =  parseFloat(calculatorInputs.bioequivalenceLimit);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'ratioOfTreatments',
      'bioequivalenceLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {
    let sampleSize = 2;
    let solutionReached = false;

    while(!solutionReached) {
      sampleSize += 1;

      let df = null;
      let tauUpper = null;
      let tauLower = null;
      if (this._isCrossover()) {
        tauUpper = (Math.sqrt(sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 - this.bioequivalenceLimit)) / Math.sqrt(2 * (this.populationStandardDeviation ** 2)));
        tauLower = (Math.sqrt(sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 / (1 - this.bioequivalenceLimit))) / Math.sqrt(2 * (this.populationStandardDeviation ** 2)));
        df = sampleSize - 2;
      } else if(this._isParallel()) {
        tauUpper = (Math.sqrt(this.allocationRatio * sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 - this.bioequivalenceLimit)) / Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
        tauLower = (Math.sqrt(this.allocationRatio * sampleSize) * (Math.log(this.ratioOfTreatments) - Math.log(1 / (1 - this.bioequivalenceLimit))) / Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
        df = sampleSize * (this.allocationRatio + 1) - 2;
      } else {
        throw new Error(`Unknown design: ${this.design}`);
      }

      const t = Stats.studentTInverse(1 - this.significanceLevel, df);
      const lowerTDistribution = new CustomT(-t, df, tauLower).cdfNoncentralT();
      const upperTDistribution = new CustomT(t, df, tauUpper).cdfNoncentralT();

      const currentPower = lowerTDistribution - upperTDistribution;

      if (currentPower >= this.power) {
        solutionReached = true;
      }
    }

    if(this._isCrossover()) {
      return { sampleSize: sampleSize };
    }

    if(this._isParallel()) {
      const sampleSizeGroup2 = Math.ceil(sampleSize * this.allocationRatio);
      const totalSampleSize  = sampleSizeGroup2 + sampleSize;
      return { sampleSizeGroup1: sampleSize, sampleSizeGroup2: sampleSizeGroup2 };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default BioequivalenceNormalSampleSizeCalculator
