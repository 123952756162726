import React from 'react';

const Contact = () => (
  <div>
    <h1 className="h3">Contact Us</h1>
    <p className="lead">For support queries please contact us at: <a href="mailto:sampsize-support@epigenesys.org.uk">sampsize-support@epigenesys.org.uk</a></p>
  </div>
);

export default Contact;
