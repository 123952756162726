import SuperiorityNormalSampleSizeCalculator    from "services/calculations/superiority/normal/SuperiorityNormalSampleSizeCalculator";
import SuperiorityBinarySampleSizeCalculator    from "services/calculations/superiority/binary/SuperiorityBinarySampleSizeCalculator";
import SuperioritySurvivalSampleSizeCalculator  from "services/calculations/superiority/survival/SuperioritySurvivalSampleSizeCalculator";

import EquivalenceNormalSampleSizeCalculator    from "services/calculations/equivalence/normal/EquivalenceNormalSampleSizeCalculator";
import EquivalenceBinarySampleSizeCalculator    from "services/calculations/equivalence/binary/EquivalenceBinarySampleSizeCalculator";
import EquivalenceSurvivalSampleSizeCalculator  from "services/calculations/equivalence/survival/EquivalenceSurvivalSampleSizeCalculator";

import NonInferiorityNormalSampleSizeCalculator   from "services/calculations/non_inferiority/normal/NonInferiorityNormalSampleSizeCalculator";
import NonInferiorityBinarySampleSizeCalculator   from "services/calculations/non_inferiority/binary/NonInferiorityBinarySampleSizeCalculator";
import NonInferioritySurvivalSampleSizeCalculator from "services/calculations/non_inferiority/survival/NonInferioritySurvivalSampleSizeCalculator";

import BioequivalenceNormalSampleSizeCalculator from "services/calculations/bioequivalence/normal/BioequivalenceNormalSampleSizeCalculator";

import PrecisionNormalSampleSizeCalculator      from 'services/calculations/precision/normal/PrecisionNormalSampleSizeCalculator';
import PrecisionBinarySampleSizeCalculator      from 'services/calculations/precision/binary/PrecisionBinarySampleSizeCalculator';
import PrecisionSurvivalSampleSizeCalculator    from 'services/calculations/precision/survival/PrecisionSurvivalSampleSizeCalculator';

const CALCULATORS = {

  superiority: {
    normal:   SuperiorityNormalSampleSizeCalculator,
    binary:   SuperiorityBinarySampleSizeCalculator,
    survival: SuperioritySurvivalSampleSizeCalculator
  },
  equivalence: {
    normal:   EquivalenceNormalSampleSizeCalculator,
    binary:   EquivalenceBinarySampleSizeCalculator,
    survival: EquivalenceSurvivalSampleSizeCalculator
  },
  "non-inferiority": {
    normal:   NonInferiorityNormalSampleSizeCalculator,
    binary:   NonInferiorityBinarySampleSizeCalculator,
    survival: NonInferioritySurvivalSampleSizeCalculator
  },
  bioequivalence: {
    normal: BioequivalenceNormalSampleSizeCalculator
  },
  precision: {
    normal:   PrecisionNormalSampleSizeCalculator,
    binary:   PrecisionBinarySampleSizeCalculator,
    survival: PrecisionSurvivalSampleSizeCalculator
  }
}

const SampleSizeFactory = function(procedure, endpoint, inputs) {
  if(!CALCULATORS[procedure]) {
    throw new Error(`Invalid procedure ${procedure}`);
  }

  const calculatorClass = CALCULATORS[procedure][endpoint];

  if(calculatorClass) {
    return new calculatorClass(inputs);
  } else {
    throw new Error(`Invalid endpoint ${endpoint}`);
  }
}

export default SampleSizeFactory;
