import * as Stats from 'services/calculations/stats';

class SuperioritySurvivalSampleSizeCalculator {

  constructor (calculatorInputs){
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.significance = parseFloat(calculatorInputs.significance);
    this.power = parseFloat(calculatorInputs.power);
    this.hazardRatio = parseFloat(calculatorInputs.hazardRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'significance',
      'hazardRatio'
    ];
    return meaningfulInputs;
  }

  calculate() {
    const numerator = 2 * ((Stats.uGaussianInverse(1 - (this.significanceLevel/2)) + Stats.uGaussianInverse(this.power)) ** 2);
    const denominator = (Math.log(this.hazardRatio) ** 2);
    return { events: Math.ceil(numerator/denominator) }
  }
}

export default SuperioritySurvivalSampleSizeCalculator;
