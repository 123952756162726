import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const AverageAnticipatedResponseInput = ({treatment_identifier}) => (
  <Field
    label="Average Anticipated Response"
    name="averageAnticipatedResponse"
    component={NumberField}
    validate={validate}
  />
);

function validate(value) {
  
}

export default AverageAnticipatedResponseInput;
