import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { Acknowledgments, Methods, Contact, NotFound } from "components/pages";
import Calculator from "containers/Calculator";

const App = () => (
  <BrowserRouter>
    <div className="container">

      <div className="row">
        <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 bg-white shadow-sm">
          <Navbar />

          <div className="pt-2 pb-3">
            <Switch>
              <Route exact path="/" component={Calculator} />
              <Route path="/acknowledgments" component={Acknowledgments} />
              <Route path="/contact" component={Contact} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  </BrowserRouter>
);

export default App;
