import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';
class PrecisionCalculator {

  constructor (calculatorInputs) {
    this.sampleSize =  parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.significance =  parseFloat(calculatorInputs.significance);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'meanDifference',
      'precisionLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {

    let precision = null;
    if(this._isCrossover()) {
      precision = Math.sqrt((2 * (Stats.studentTInverse(1 - (this.significanceLevel / this.significance), this.sampleSize - 2) ** 2) * (this.populationStandardDeviation ** 2)) / this.sampleSize)
    } else if(this._isParallel()) {
      precision = Math.sqrt(((this.allocationRatio + 1) * (Stats.studentTInverse(1 - (this.significanceLevel / this.significance), this.sampleSize  * (this.allocationRatio + 1) - 2) ** 2) * (this.populationStandardDeviation ** 2)) / (this.allocationRatio * this.sampleSize))
    } else {
      throw new Error(`Unknown design: ${this.design}`);
    }
    const result = { precision:  _.round(precision, 2) }

    if( this._isParallel()){
      result['sampleSizePerGroup'] = this.sampleSize
    }
    return result

  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default PrecisionCalculator
