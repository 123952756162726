import React from "react";
import { Field } from "redux-form";
import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const ResponseAnticipatedInput = ({treatment_identifier}) => (
  <Field
    label={`Response Anticipated Treat. ${treatment_identifier}`}
    name={`response${treatment_identifier}`}
    component={NumberField}
    validate={validate}
  />
);

function validate(value) {
  if ( !validNumber(value, { min: 0, max: 1 }) )
    return "Must be between 0 and 1";
}

export default ResponseAnticipatedInput;
