import * as Stats from 'services/calculations/stats';
import CustomT from "services/calculations/CustomT";

class EquivalenceNormalSampleSizeCalculator {

  constructor (calculatorInputs) {
    this.power =  parseFloat(calculatorInputs.power);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.meanDifference =  parseFloat(calculatorInputs.meanDifference);
    this.equivalenceLimit =  parseFloat(calculatorInputs.equivalenceLimit);
    this.populationStandardDeviation =  parseFloat(calculatorInputs.populationStandardDeviation);
    this.design = calculatorInputs.design;
    this.allocationRatio =  parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'power',
      'significanceLevel',
      'meanDifference',
      'equivalenceLimit',
      'populationStandardDeviation'
    ];
    if(this._isParallel())
      meaningfulInputs = meaningfulInputs.concat(['allocationRatio']);
    return meaningfulInputs;
  }


  calculate() {
    let sampleSize = 2;
    let solutionReached = false;

    while(!solutionReached) {
      sampleSize += 1;

      const tauUpper = ((this.meanDifference + this.equivalenceLimit) * Math.sqrt(this.allocationRatio * sampleSize)) / (Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
      const tauLower = ((this.meanDifference - this.equivalenceLimit) * Math.sqrt(this.allocationRatio * sampleSize)) / (Math.sqrt((this.allocationRatio + 1) * (this.populationStandardDeviation ** 2)));
      let t = null;
      let df = null;

      if (this._isCrossover()) {
        this.allocationRatio = 1;
        t  = Stats.studentTInverse(1 - this.significanceLevel, sampleSize - 2);
        df = sampleSize - 2;
      } else if(this._isParallel()) {
        t  = Stats.studentTInverse(1 - this.significanceLevel, (sampleSize * (this.allocationRatio + 1)) - 2);
        df = sampleSize * (this.allocationRatio + 1) - 2;
      } else {
        throw new Error(`Unknown design: ${this.design}`);
      }

      const lowerTDistribution = new CustomT(-t, df, tauLower).cdfNoncentralT();
      const upperTDistribution = new CustomT(t, df, tauUpper).cdfNoncentralT();

      const currentPower = lowerTDistribution - upperTDistribution;

      if (currentPower >= this.power) {
        solutionReached = true;
      }
    }

    if(this._isCrossover()) {
      return { sampleSize: sampleSize };
    }

    if(this._isParallel()) {
      const sampleSizeGroup2 = Math.ceil(sampleSize * this.allocationRatio);
      const totalSampleSize  = sampleSizeGroup2 + sampleSize;
      return { sampleSizeGroup1: sampleSize, sampleSizeGroup2: sampleSizeGroup2 };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default EquivalenceNormalSampleSizeCalculator
