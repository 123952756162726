import * as Stats from 'services/calculations/stats';

class PrecisionBinarySampleSizeCalculator {

  constructor (calculatorInputs) {
    this.precision =  parseFloat(calculatorInputs.precision);
    this.significanceLevel =  parseFloat(calculatorInputs.significanceLevel);
    this.significance =  parseFloat(calculatorInputs.significance);
    this.averageAnticipatedResponse =  parseFloat(calculatorInputs.averageAnticipatedResponse);
    this.design = calculatorInputs.design;
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'precision',
      'significanceLevel',
      'significance',
      'averageAnticipatedResponse'
    ];
    return meaningfulInputs;
  }


  calculate() {
    const nA = Math.ceil((2 * this.averageAnticipatedResponse * (1 - this.averageAnticipatedResponse) * Math.pow(Stats.uGaussianInverse(1 - this.significanceLevel / this.significance), 2)) / Math.pow(this.precision, 2));
    const nB = nA;

    if (this._isCrossover()) {
      return { sampleSize: Math.ceil(nA) }
    } else if (this._isParallel()) {
      return { sampleSizeGroup1: Math.ceil(nA), sampleSizeGroup2: Math.ceil(nB) };
    }

  }

   _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default PrecisionBinarySampleSizeCalculator
