import * as Stats from 'services/calculations/stats';
import _ from 'lodash/math';

class SuperiorityBinaryPowerCalculator {

  constructor (calculatorInputs) {
    this.design = calculatorInputs.design;
    this.sampleSize = parseFloat(calculatorInputs.sampleSize);
    this.significanceLevel = parseFloat(calculatorInputs.significanceLevel);
    this.significance = calculatorInputs.significance;
    this.continuityCorrection = (String(calculatorInputs.continuityCorrection) == "true")
    this.responseA = parseFloat(calculatorInputs.responseA);
    this.responseB = parseFloat(calculatorInputs.responseB);
    this.allocationRatio = parseFloat(calculatorInputs.allocationRatio);
  }

  getMeaningfulInputs() {
    let meaningfulInputs = [
      'sampleSize',
      'significanceLevel',
      'significance',
      'continuityCorrection',
      'responseA',
      'responseB',
    ];
    return meaningfulInputs;
  }

  calculate() {

    let correctedSampleSize = this.sampleSize;

    if (this.continuityCorrection) {
      const delta = Math.abs(this.responseA - this.responseB);
      correctedSampleSize = 1;
      while((correctedSampleSize / 4.0 * Math.pow((1.0 + Math.sqrt(1 + (4.0 / (correctedSampleSize * delta)))), 2.0)) < this.sampleSize) {
        correctedSampleSize += 1;
      }
    }

    const piBar = (this.responseA + this.responseB)/2;
    const numerator = Math.sqrt(correctedSampleSize * Math.pow((this.responseA - this.responseB), 2)) - (Stats.uGaussianInverse(1 - this.significanceLevel / this.significance) * Math.sqrt(2 * piBar * (1 - piBar)));
    const denominator = Math.sqrt(this.responseA * (1 - this.responseA) + this.responseB * (1 - this.responseB));
    const power = Stats.uGaussian(numerator / denominator);

    if (this._isCrossover()) {
      return { power: _.round(power, 2) }
    } else if (this._isParallel()) {
      return { power: _.round(power, 2), sampleSizePerGroup: this.sampleSize }
    }

  }

  _isCrossover() {
    return this.design === 'crossover';
  }
  _isParallel() {
    return this.design === 'parallel';
  }

}

export default SuperiorityBinaryPowerCalculator;
