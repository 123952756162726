import { Field } from "redux-form";
import React from "react";

import NumberField from "components/forms/NumberField";
import validNumber from 'services/validators/number';

const AllocationRatioInput = ({design}) => (
  design === 'parallel' &&
    <Field
      label="Allocation Ratio"
      name="allocationRatio"
      component={NumberField}
      validate={validate}
    />
);

function validate(value) {
  if ( !validNumber(value) )
    return "Must be a number"
}

export default AllocationRatioInput;
