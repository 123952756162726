import React from "react";
import { Field } from "redux-form";
import SelectField from "components/forms/SelectField";

const ContinuityCorrectionInput = () => (
  <Field
    label="Continuity Correction"
    name="continuityCorrection"
    component={SelectField}
    options={[[false, 'No'], [true, 'Yes']]}
  />
);

export default ContinuityCorrectionInput;
